import React, {useEffect, useRef, useState} from "react";
import Icon from "../helper/Icon";
import {Link} from "gatsby";
import styled from "styled-components";
import {navigate} from 'gatsby';
import DatenschutzAcceptance from "./DatenschutzAcceptance";

const Menu = styled.div`
    display: ${({nav}) => (nav ? "flex" : "none")} !important;
`

const Navigation = ({location, navigationRef, isImageIntro}) => {
    const [nav, changeNavState] = useState(false);
    const [nav2, changeNav2State] = useState(false);
    const [navBg, changeNavBgState] = useState(isImageIntro);

    const menuRef = useRef();

    const changeNav = () => {
        if (nav) {
            document.body.classList.remove("stop-scrolling");
            menuRef.current.classList.add('fade-out');
            changeNav2State(false)

            setTimeout(() => {
                changeNavState(false);
                if (menuRef.current) {
                    menuRef.current.classList.remove('fade-out');
                }
            }, 300)
        } else {
            document.body.classList.add("stop-scrolling");
            changeNavState(true);
            changeNav2State(true);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (isImageIntro) {
                let currentScrollPos = window.scrollY;
                if (currentScrollPos > 100) {
                    changeNavBgState(false);
                } else {
                    changeNavBgState(true);
                }
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    //${!navBg ? '' : 'atStart'} ${!nav2 ? '' : 'atStart'}
    return (
        <div>
            <nav className={`navigation ${navBg ? 'transparentBg' : 'coloredBg'}`} id={'navigationBar'} ref={navigationRef}>
                <div className={`left`}>
                    <Link to={"/"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64.187" height="54.13" viewBox="0 0 64.187 54.13">
                            <path id="Path_1" data-name="Path 1" d="M33.677,54.119c-1.43,0-2.78-.012-4.129.006-.5.007-.429-.315-.427-.619q.042-5.042.086-10.082.024-2.829.05-5.656.18-16.2.373-32.4c.007-.5-.133-.666-.617-.744-2.178-.353-4.349-.747-6.679-1.153v50.6H17.747V37.721c-.245.084-.473.15-.691.24a14.482,14.482,0,0,0-8.511,8.35c-.932,2.424-1.892,4.838-2.817,7.266-.143.376-.3.563-.733.552-1.207-.028-2.414-.01-3.712-.01a6.043,6.043,0,0,1,.193-.856c1.057-2.726,2.018-5.495,3.211-8.16A22.975,22.975,0,0,1,18.245,32.751c.3-.113.618-.181,1.035-.3a3.932,3.932,0,0,0-.448-.218,32.017,32.017,0,0,1-9.308-4.89,16.635,16.635,0,0,1-4.886-6.455c-.228-.466-.418-.632-.964-.51-1.237.278-2.421.233-3.216-1a1.658,1.658,0,0,1,.454-2.64c.543-.389,1.194-.624,1.765-.979a.961.961,0,0,0,.451-.613C3.243,11.382,5.087,8.538,7.9,6.243A30.88,30.88,0,0,1,19.794.617,19.862,19.862,0,0,1,25.079,0c4.794.034,9.589.018,14.384,0a1.394,1.394,0,0,1,1.068.451A161.149,161.149,0,0,1,52.771,13.4a59.743,59.743,0,0,1,7.067,10.173,16.346,16.346,0,0,1,1.653,9.235,1.357,1.357,0,0,0,.522,1.325,9.923,9.923,0,0,1,1.505,1.527,2.349,2.349,0,0,1,.194,3.056,2.668,2.668,0,0,1-3.022,1.236c-.454-.118-.588-.019-.732.405a26.812,26.812,0,0,1-2.886,5.88,15.486,15.486,0,0,1-9.087,6.5c-2.073.6-4.23.918-6.353,1.342a6.12,6.12,0,0,1-1.087.009q-.249-25.425-.5-50.915l-6.89,1.56c.173,16.445.346,32.871.52,49.381M44.642,11.153v1.131q.052,17.85.094,35.7c0,.526.117.725.678.7a11.273,11.273,0,0,0,8.19-3.938A18.262,18.262,0,0,0,57.948,29.5,19.459,19.459,0,0,0,55.8,23.52a29.052,29.052,0,0,0-9.97-11.676c-.352-.231-.729-.425-1.189-.691m-27-6.348c-1.286.712-2.424,1.307-3.527,1.96a22.593,22.593,0,0,0-5.842,4.644,5.791,5.791,0,0,0-1.724,3.965,11.047,11.047,0,0,0,1.34,4.872,13.49,13.49,0,0,0,7.154,6.074c.835.34,1.712.574,2.6.866Z" transform="translate(0 0.001)"/>
                        </svg>
                    </Link>
                </div>
                <div className={`right`}>
                    <div className="desktop">
                        <Link to={"/"} className={"uppercase nav-link hover-underline"}><h4>Startseite</h4></Link>
                        <Link to={"/termine"} className={"uppercase nav-link hover-underline"}><h4>Termine</h4></Link>
                        <Link to={"/produktionen"} className={"uppercase nav-link hover-underline"}><h4>Produktionen</h4></Link>
                        <Link to={"/about"} className={"uppercase nav-link hover-underline"}><h4>Über Uns</h4></Link>
                        <Link to={"/kontakt"} className={"uppercase nav-link hover-underline"}><h4>Kontakt</h4></Link>
                    </div>
                    <div className="mobile">
                        <div className={nav2 ? "burger close" : "burger"} onClick={() => {
                            changeNav();
                        }}>
                            <div className={"line bg-dark"}></div>
                            <div className={"line bg-dark"}></div>
                            <div className={"line bg-dark"}></div>
                        </div>
                    </div>
                </div>
                <Menu className={`menu fade-in`} nav={nav} ref={menuRef}>
                    <div className="menu__link__container">
                        <Link to={"/"} className={"uppercase menu-link hover-underline"} onClick={() => {changeNav();}}><h4>Startseite</h4></Link>
                        <Link to={"/termine"} className={"uppercase menu-link hover-underline"} onClick={() => {changeNav();}}><h4>Termine</h4></Link>
                        <Link to={"/produktionen"} className={"uppercase menu-link hover-underline"} onClick={() => {changeNav();}}><h4>Produktionen</h4></Link>
                        <Link to={"/about"} className={"uppercase menu-link hover-underline"} onClick={() => {changeNav();}}><h4>Über Uns</h4></Link>
                        <Link to={"/kontakt"} className={"uppercase menu-link hover-underline"} onClick={() => {changeNav();}}><h4>Kontakt</h4></Link>
                    </div>
                </Menu>
            </nav>
        </div>
    )
}
export default Navigation;
