import { PortableText } from '@portabletext/react';
import React from 'react';
import YouTube from "react-youtube";
import {GatsbyImage} from "gatsby-plugin-image";
import {getSanityImageData} from "../../utils/getSanityImageData";
import { getImage, getImageDimensions } from '@sanity/asset-utils';
import sanityConfig from '../../../sanity-config'
import getYouTubeId from 'get-youtube-id'
import Vimeo from '@u-wave/react-vimeo';
import {Link} from "gatsby";


const textComponents = {
    block: {
        normal: ({ children }) => <p className={"portabletext is-animated"}>{children}</p>,
        h1: ({ children }) => <h1>{children}</h1>,
        h2: ({ children }) => <h2>{children}</h2>,
        h3: ({ children }) => <h3>{children}</h3>,
        h4: ({ children }) => <h4 className={"roboto portableheadline is-animated"}>{children}</h4>,
        h5: ({ children }) => <h5>{children}</h5>,
        h6: ({ children }) => <h6>{children}</h6>,
    },
    marks: {
        strong: ({children}) => <strong>{children}</strong>,
        link: ({value, children}) => {
            const target = (value?.href || '').startsWith('http') ? '_blank' : undefined
            return (
                <a href={value?.href} target={target} rel={target === '_blank' && 'noindex nofollow'} className="hover-moveup underlined uppercase archivo link text-link">
                    {children}
                </a>
            )
        },
        internalLink: ({value, children}) => {
            const slug = value.reference.slug?.current;
            if (!slug) {
                console.error("Missing slug for internal link", value);
                return <span>{children}</span>; // or handle the error as you wish
            }

            // Constructing the internal path (you may need to adjust this pattern based on your URL structure)
            const internalPath = `/produktionen/${slug}/`;

            return (
                <Link to={internalPath} className="hover-moveup underlined uppercase archivo link text-link">
                    {children}
                </Link>
            );
        }
    },
    types: {
        imageAlt: ({value}) => {
            const imageData = getImage(value.asset, sanityConfig).asset;
            const {width, height} = getImageDimensions(value);

            const image = {
                url: imageData.url,
                width,
                height,
            };

            const gatsbyImageData = getSanityImageData({
                image,
                layout: 'constrained',
            });

            if(value.link){
                return (
                    <a href={value.link} target={"_blank"}>
                        <div className={` is-animated imageAlt blockcontent-image-container ${value.credits ? "withCredits" : ""}`}>
                            <GatsbyImage className={`page-image blockcontent-image`} image={gatsbyImageData} alt={value.alt}  title={value.credits}></GatsbyImage>
                        </div>
                    </a>
                );
            }
            else {
                return (
                    <div className={`is-animated imageAlt blockcontent-image-container ${value.credits ? "withCredits" : ""}`}>
                        <GatsbyImage className={`page-image blockcontent-image`} image={gatsbyImageData} alt={value.alt} title={value.credits}></GatsbyImage>
                    </div>
                );
            }
        },
        youtube: ({value}) => {
            const { url } = value
            const id = getYouTubeId(url)
            return (<YouTube className="bodyImage right blogiframe is-animated" videoId={id} />)
        },
        vimeo: ({value}) => {
            const id = value.video
            return (<Vimeo className="bodyImage right blogiframe is-animated" video={id} />)
        },
        quote: ({value}) => {
            console.log(value);
            const text = value.text;
            const author = value.person;
            return <div className={"quote is-animated"}>
                <p className="quote-text">{text}</p>
                <p className="quote-author">{author}</p>
            </div>
        }
    }
};

function MyPortableText({ value }) {
    return <PortableText value={value} components={textComponents} />;
}

export default MyPortableText;
