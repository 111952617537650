import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import Transition from '../components/helper/transition'
import Navigation from "../components/layout/Navigation";

import {AnimatePresence, motion} from "framer-motion";

const Layout = ({children, location}) => {

    let navigationRef = useRef();

    let isImageIntro = location.pathname === '/' || location.pathname === '/about/' || location.pathname.includes('/produktionen/') && location.pathname !== '/produktionen/';

    console.log(navigationRef);

    const animationComplete = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        isImageIntro = location.pathname === '/' || location.pathname === '/about/' || location.pathname.includes('/produktionen/') && location.pathname !== '/produktionen/';
    }, [location])

    const changeNav = () => {
        if (isImageIntro && window.scrollY <= 100) {
            if (navigationRef.current.classList.contains('coloredBg')) {
                navigationRef.current.classList.remove('coloredBg');
            }
            if (!navigationRef.current.classList.contains('transparentBg')) {
                navigationRef.current.classList.add('transparentBg');
            }
        } else {
            if (navigationRef.current.classList.contains('transparentBg')) {
                navigationRef.current.classList.remove('transparentBg');
            }
            if (!navigationRef.current.classList.contains('coloredBg')) {
                navigationRef.current.classList.add('coloredBg');
            }
        }
    }

    return (<>
        <Navigation location={location} navigationRef={navigationRef} isImageIntro={isImageIntro}></Navigation>
        <AnimatePresence initial={false} mode="wait">
            <motion.div key={location.pathname} initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{
                            ease: "easeInOut", duration: .5
                        }}
                        onAnimationStart={changeNav}
                        onAnimationComplete={animationComplete}>
                {children}
            </motion.div>
        </AnimatePresence>
    </>)
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
